function getjson() {
    var json = [
        { title: '项目对接及现场调研报告', description: '项目对接及现场调研报告', content: '<div class="mceTmpl"><div class="mceTmpl">\n' +
                '<table style="border-collapse: collapse; width: 100%; height: 724px; border-color: #000000; float: left;" border="1" width="100%">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;">\n' +
                '<td class="xl244" style="width: 98.1404%; height: 36px; text-align: center;" colspan="4" width="664" height="48"><strong><span style="font-size: 14pt;">项目对接及现场调研报告</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;">\n' +
                '<td class="xl246" style="width: 98.1404%; height: 21px; text-align: right;" colspan="4" width="664" height="28"><strong><span style="mso-spacerun: yes;">&nbsp;&nbsp; </span>年<span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">项目名称</td>\n' +
                '<td class="xl247" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 51.75pt;">\n' +
                '<td class="xl255" style="width: 8.2262%; height: 138px; text-align: center;" rowspan="2" width="103" height="138">项目对接人员</td>\n' +
                '<td class="xl231" style="width: 2.59812%; height: 69px; text-align: center;">甲方</td>\n' +
                '<td class="xl248" style="width: 87.3161%; height: 69px;" colspan="2" width="511" height="69">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 51.75pt;">\n' +
                '<td class="xl233" style="width: 2.59812%; height: 69px; text-align: center;">乙方</td>\n' +
                '<td class="xl248" style="width: 87.3161%; height: 69px;" colspan="2" width="511" height="69">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">调研时间</td>\n' +
                '<td class="xl247" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">调研地点</td>\n' +
                '<td class="xl250" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">调研人员</td>\n' +
                '<td class="xl250" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">调研方式</td>\n' +
                '<td class="xl250" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">调研范围</td>\n' +
                '<td class="xl250" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 285.75pt;">\n' +
                '<td class="xl234" style="width: 8.2262%; height: 285px; text-align: center;" width="103" height="381"><span style="mso-spacerun: yes;">调研内容&nbsp;</span></td>\n' +
                '<td class="xl251" style="width: 89.9142%; height: 285px;" colspan="3" width="561" height="381">（结合项目概况、现状场地分析、周边环境情况）</td>\n' +
                '</tr>\n' +
                '<tr style="height: 34.5pt;">\n' +
                '<td class="xl250" style="width: 63.5546%; height: 34px;" colspan="3" width="278" height="46">经营部签字：</td>\n' +
                '<td class="xl186" style="width: 34.5858%; height: 34px;">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;">\n' +
                '<td class="xl82" style="height: 30px; width: 8.2262%; text-align: center;" height="40">说 明</td>\n' +
                '<td class="xl254" style="width: 89.9142%; height: 30px;" colspan="3" width="561" height="40">1.记录每次跟客户进行工作对接的情况；2.调研记录不是给自己的看的，是给别人看的，所以应不厌其详。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table>\n' +
                '</div></div>'},
        { title: '项目概况及需求分析报告', description: '项目概况及需求分析报告', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%; height: 639px; border-color: #000000; float: right;" border="1" width="100%">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;">\n' +
                '<td class="xl257" style="width: 666.75px; height: 36px; text-align: center;" colspan="5" width="731" height="48"><strong><span style="font-family: 宋体; font-size: 14pt;">项目概况及需求分析报告</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.75pt;">\n' +
                '<td class="xl246" style="width: 666.75px; height: 21px; text-align: right;" colspan="5" width="731" height="29"><span style="mso-spacerun: yes;">&nbsp;&nbsp; </span>年<span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;&nbsp; </span>日</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;">\n' +
                '<td class="xl170" style="width: 66.6719px; height: 36px;" width="72" height="48">项目名称</td>\n' +
                '<td class="xl170" style="width: 373.078px; height: 36px;" colspan="2" width="410" height="48">&nbsp;</td>\n' +
                '<td class="xl185" style="width: 70.25px; height: 36px;" width="76">合同编号</td>\n' +
                '<td class="xl185" style="width: 156.75px; height: 36px;" width="173">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;">\n' +
                '<td class="xl82" style="height: 36px; width: 66.6719px;" height="48">项目总监</td>\n' +
                '<td class="xl170" style="width: 287.172px; height: 36px;" width="317">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 85.9062px; height: 36px;" width="93">项目负责人</td>\n' +
                '<td class="xl259" style="width: 227px; height: 36px;" colspan="2" width="249" height="48">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;">\n' +
                '<td class="xl170" style="width: 66.6719px; height: 36px;" width="72" height="48">设计人员</td>\n' +
                '<td class="xl170" style="width: 600.078px; height: 36px;" colspan="4" width="659" height="48">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 384.0pt;">\n' +
                '<td class="xl82" style="width: 666.75px; height: 384px;" colspan="5" width="731" height="512">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;">\n' +
                '<td class="xl262" style="width: 353.844px; height: 36px;" colspan="2" width="389" height="48">经营部签字：</td>\n' +
                '<td class="xl262" style="width: 312.906px; height: 36px;" colspan="3" width="342" height="48">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 54.75pt;">\n' +
                '<td class="xl82" style="height: 54px; width: 66.6719px;" height="73">说 明</td>\n' +
                '<td class="xl254" style="width: 600.078px; height: 54px;" colspan="4" width="659" height="73">反映对项目的理解，对所有需要解决的问题的分析；对所有有关国家政策、法律法规理解之后(反映足够的政策理解)，对项目提出的限制性、鼓励性的要求。需求分析是动态调整的，每次跟甲方对接沟通、专家审查、领导汇报、意见征询之后，都需要及时调整完善项目需求分析。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},
        { title: '项目生产任务单', description: '项目生产任务单', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%; border-color: #000000;" border="1" width="550pt" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl265" style="width: 550pt; height: 36pt; text-align: center;" colspan="4" width="733" height="48" data-mce-fragment="1"><strong><span style="font-size: 14pt;">项目生产任务单</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl266" style="width: 550pt; height: 21pt; text-align: right;" colspan="4" width="733" height="28" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 74pt; height: 30.75pt; text-align: center;" width="99" height="41" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl259" style="width: 476pt; height: 30.75pt;" colspan="3" width="634" height="41" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl174" style="height: 30.75pt; text-align: center;" height="41" data-mce-fragment="1">项目总监</td>\n' +
                '<td class="xl170" style="width: 165pt;" width="220" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 102pt; text-align: center;" width="136" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl174" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 74pt; height: 30.75pt; text-align: center;" width="99" height="41" data-mce-fragment="1">设计人员</td>\n' +
                '<td class="xl170" style="width: 476pt; height: 30.75pt;" colspan="3" width="634" height="41" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl268" style="width: 239pt; height: 30.75pt;" colspan="2" width="319" height="41" data-mce-fragment="1">下达日期：</td>\n' +
                '<td class="xl268" style="width: 311pt; height: 30.75pt;" colspan="2" width="414" height="41" data-mce-fragment="1">完成日期：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 225.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl206" style="width: 74pt; height: 225.0pt;" width="99" height="300" data-mce-fragment="1">项目要求</td>\n' +
                '<td class="xl269" style="width: 476pt; height: 225.0pt;" colspan="3" width="634" height="300" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 231.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl206" style="width: 74pt; height: 231pt; text-align: center;" width="99" height="308" data-mce-fragment="1">项目设计说明</td>\n' +
                '<td class="xl272" style="width: 476pt; height: 231.0pt;" colspan="3" width="634" height="308" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl268" style="width: 239pt; height: 30.0pt;" colspan="2" width="319" height="40" data-mce-fragment="1">经营部签字:<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>\n' +
                '<td class="xl250" style="width: 311pt; height: 30.0pt;" colspan="2" width="414" height="40" data-mce-fragment="1">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},
        { title: '项目技术服务准备书', description: '项目技术服务准备书', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%; border-color: #000000;" border="1" width="518pt" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl265" style="width: 518pt; height: 36pt; text-align: center;" colspan="4" width="690" height="48" data-mce-fragment="1"><strong><span style="font-size: 14pt;">项目技术服务准备书</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl266" style="width: 518pt; height: 21pt; text-align: right;" colspan="4" width="690" height="28" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 74pt; height: 30.75pt; text-align: center;" width="99" height="41" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl259" style="width: 443pt; height: 30.75pt;" colspan="3" width="591" height="41" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 30.75pt; text-align: center;" height="41" data-mce-fragment="1">项目总监</td>\n' +
                '<td class="xl170" style="width: 151pt;" width="201" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 102pt; text-align: center;" width="136" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl82" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 178.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 178.5pt;" height="238" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl206" style="width: 151pt;" width="201" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl206" style="width: 102pt;" width="136" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl207" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 277.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl206" style="width: 74pt; height: 277.5pt;" width="99" height="370" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl272" style="width: 443pt; height: 277.5pt;" colspan="3" width="591" height="370" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl268" style="width: 225pt; height: 30.0pt;" colspan="2" width="300" height="40" data-mce-fragment="1">经营部签字:<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>\n' +
                '<td class="xl250" style="width: 293pt; height: 30.0pt;" colspan="2" width="390" height="40" data-mce-fragment="1">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 27.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 27pt; text-align: center;" height="36" data-mce-fragment="1">说 明</td>\n' +
                '<td class="xl170" style="width: 443pt; height: 27.0pt;" colspan="3" width="591" height="36" data-mce-fragment="1">在充分理解项目需求、与客户沟通的基础上，为客户提供的公司产品和技术服务方案。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},
        { title: '项目工作分解表', description: '项目工作分解表', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%; border-color: #000000;" border="1" width="518pt" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl265" style="width: 518pt; height: 36pt; text-align: center;" colspan="4" width="690" height="48" data-mce-fragment="1"><strong><span style="font-size: 14pt;">项目工作分解表</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl266" style="width: 518pt; height: 21pt; text-align: right;" colspan="4" width="690" height="28" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 74pt; height: 30.75pt; text-align: center;" width="99" height="41" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl259" style="width: 443pt; height: 30.75pt;" colspan="3" width="591" height="41" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 30.75pt; text-align: center;" height="41" data-mce-fragment="1">项目总监</td>\n' +
                '<td class="xl170" style="width: 151pt;" width="201" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 102pt; text-align: center;" width="136" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl82" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 178.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 178.5pt;" height="238" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl206" style="width: 151pt;" width="201" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl206" style="width: 102pt;" width="136" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl207" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 277.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl206" style="width: 74pt; height: 277.5pt;" width="99" height="370" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl272" style="width: 443pt; height: 277.5pt;" colspan="3" width="591" height="370" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl268" style="width: 225pt; height: 30.0pt;" colspan="2" width="300" height="40" data-mce-fragment="1">经营部签字:<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>\n' +
                '<td class="xl250" style="width: 293pt; height: 30.0pt;" colspan="2" width="390" height="40" data-mce-fragment="1">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 27.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 27pt; text-align: center;" height="36" data-mce-fragment="1">说 明</td>\n' +
                '<td class="xl170" style="width: 443pt; height: 27.0pt;" colspan="3" width="591" height="36" data-mce-fragment="1">在充分理解项目需求、与客户沟通的基础上，为客户提供的公司产品和技术服务方案。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},
        { title: '下面方案设计说明', description: '下面方案设计说明', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%; border-color: #000000;" border="1" width="518pt" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl265" style="width: 518pt; height: 36pt; text-align: center;" colspan="4" width="690" height="48" data-mce-fragment="1"><strong><span style="font-size: 14pt;">下面方案设计说明</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl266" style="width: 518pt; height: 21pt; text-align: right;" colspan="4" width="690" height="28" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 74pt; height: 30.75pt; text-align: center;" width="99" height="41" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl259" style="width: 443pt; height: 30.75pt;" colspan="3" width="591" height="41" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 30.75pt; text-align: center;" height="41" data-mce-fragment="1">项目总监</td>\n' +
                '<td class="xl170" style="width: 151pt;" width="201" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 102pt; text-align: center;" width="136" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl82" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 178.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 178.5pt;" height="238" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl206" style="width: 151pt;" width="201" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl206" style="width: 102pt;" width="136" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl207" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 277.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl206" style="width: 74pt; height: 277.5pt;" width="99" height="370" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl272" style="width: 443pt; height: 277.5pt;" colspan="3" width="591" height="370" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl268" style="width: 225pt; height: 30.0pt;" colspan="2" width="300" height="40" data-mce-fragment="1">经营部签字:<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>\n' +
                '<td class="xl250" style="width: 293pt; height: 30.0pt;" colspan="2" width="390" height="40" data-mce-fragment="1">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 27.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 27pt; text-align: center;" height="36" data-mce-fragment="1">说 明</td>\n' +
                '<td class="xl170" style="width: 443pt; height: 27.0pt;" colspan="3" width="591" height="36" data-mce-fragment="1">在充分理解项目需求、与客户沟通的基础上，为客户提供的公司产品和技术服务方案。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},

        { title: '项目核心思路框架及深化完善', description: '项目核心思路框架及深化完善', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%;" border-color="#000000" border="1" width="724" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl291" style="width: 543pt; height: 36pt; text-align: center;" colspan="5" width="724" height="48" data-mce-fragment="1"><strong><span style="font-size: 14pt;">项目核心思路框架及深化完善</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl282" style="width: 543pt; height: 21pt; text-align: right;" colspan="5" width="724" height="28" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 60pt; height: 30pt; text-align: center;" width="80" height="40" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl259" style="width: 483pt; height: 30.0pt;" colspan="4" width="644" height="40" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 30pt; text-align: center;" height="40" data-mce-fragment="1">项目总监</td>\n' +
                '<td class="xl170" style="width: 216pt;" width="288" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 72pt; text-align: center;" width="96" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl170" style="width: 195pt; height: 30.0pt;" colspan="2" width="260" height="40" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 39.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 60pt; height: 39pt; text-align: center;" width="80" height="52" data-mce-fragment="1">设计人员</td>\n' +
                '<td class="xl170" style="width: 483pt; height: 39.0pt;" colspan="4" width="644" height="52" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 399.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="width: 543pt; height: 399.75pt;" colspan="5" width="724" height="533" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl250" style="width: 276pt; height: 30.0pt;" colspan="2" width="368" height="40" data-mce-fragment="1">经营部签字：</td>\n' +
                '<td class="xl250" style="width: 267pt; height: 30.0pt;" colspan="3" width="356" height="40" data-mce-fragment="1">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 36pt; text-align: center;" height="48" data-mce-fragment="1">说 明</td>\n' +
                '<td class="xl280" style="width: 483pt; height: 36.0pt;" colspan="4" width="644" height="48" data-mce-fragment="1">基本章节组成及核心内容。将需要解决的问题用一种。输入+模型=输出。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},


        { title: '项目运营策划方案', description: '项目运营策划方案', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%" border="1" width="712" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl291" style="width: 1637.66px; height: 36pt; text-align: center;" colspan="5" width="712" height="48" data-mce-fragment="1"><strong><span style="font-size: 14pt;">项目运营策划方案</span></strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 21.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl282" style="width: 1637.66px; height: 21pt; text-align: right;" colspan="5" width="712" height="28" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 144px; height: 36pt; text-align: center;" width="144" height="48" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl259" style="width: 1493.66px; height: 36pt;" colspan="4" width="568" height="48" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 36pt; text-align: center; width: 144px;" height="48" data-mce-fragment="1">项目总监</td>\n' +
                '<td class="xl170" style="width: 193.328px;" width="193" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl170" style="width: 105.328px; text-align: center;" width="105" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl259" style="width: 1195px; height: 36pt;" colspan="2" width="270" height="48" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl170" style="width: 144px; height: 36pt; text-align: center;" width="144" height="48" data-mce-fragment="1">设计人员</td>\n' +
                '<td class="xl170" style="width: 1493.66px; height: 36pt;" colspan="4" width="568" height="48" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 387.75pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="width: 1637.66px; height: 387.75pt;" colspan="5" width="712" height="517" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl250" style="width: 337.328px; height: 36pt;" colspan="2" width="337" height="48" data-mce-fragment="1">经营部签字：</td>\n' +
                '<td class="xl250" style="width: 1300.33px; height: 36pt;" colspan="3" width="375" height="48" data-mce-fragment="1">项目负责人签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl82" style="height: 36pt; width: 144px;" height="48" data-mce-fragment="1">说 明</td>\n' +
                '<td class="xl290" style="width: 1493.66px; height: 36pt;" colspan="4" width="568" height="48" data-mce-fragment="1">规划提出项目，然后在市场分析、商业模式分析之后，提出的策划方案。做相应的PPP方案设计。企业资源对接和产业导入。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},
        { title: '项目阶段性成果会议纪要及院内审查意见表', description: '项目阶段性成果会议纪要及院内审查意见表', content: '<div class="mceTmpl"><table style="border-collapse: collapse; width: 100%" border="1" width="746" data-mce-fragment="1">\n' +
                '<tbody>\n' +
                '<tr style="height: 36.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl294" style="width: 560pt; height: 36pt; text-align: center;" colspan="6" width="746" height="48" data-mce-fragment="1"><span style="font-size: 14pt;"><strong>项目阶段性成果会议纪要及院内审查意见表</strong></span></td>\n' +
                '</tr>\n' +
                '<tr style="height: 19.5pt;" data-mce-fragment="1">\n' +
                '<td class="xl295" style="width: 560pt; height: 19.5pt; text-align: right;" colspan="6" width="746" height="26" data-mce-fragment="1"><strong>年<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>月<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp; </span>日</strong></td>\n' +
                '</tr>\n' +
                '<tr style="height: 33.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl77" style="width: 103pt; height: 33pt; text-align: center;" width="137" height="44" data-mce-fragment="1">项目名称</td>\n' +
                '<td class="xl77" style="width: 457pt; height: 33.0pt;" colspan="5" width="609" height="44" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl153" style="width: 103pt; height: 30pt; text-align: center;" width="137" height="40" data-mce-fragment="1">审查阶段</td>\n' +
                '<td class="xl153" style="width: 83pt;" width="110" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl153" style="width: 111pt; text-align: center;" width="148" data-mce-fragment="1">提交审查的日期</td>\n' +
                '<td class="xl297" style="width: 179pt; height: 30.0pt;" colspan="2" width="238" height="40" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl153" style="width: 85pt;" width="113" data-mce-fragment="1">咨询（<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>）<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp; </span>规划（<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp; </span>）</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl77" style="width: 103pt; height: 30pt; text-align: center;" width="137" height="40" data-mce-fragment="1">项目负责人</td>\n' +
                '<td class="xl77" style="width: 194pt; height: 30.0pt;" colspan="2" width="258" height="40" data-mce-fragment="1">&nbsp;</td>\n' +
                '<td class="xl77" style="width: 81pt;" width="108" data-mce-fragment="1">所长</td>\n' +
                '<td class="xl77" style="width: 182pt; height: 30.0pt;" colspan="2" width="243" height="40" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl156" style="width: 103pt; height: 30pt; text-align: center;" width="137" height="40" data-mce-fragment="1">规技委主任</td>\n' +
                '<td class="xl156" style="width: 83pt;" width="110" data-mce-fragment="1">徐 戈</td>\n' +
                '<td class="xl156" style="width: 111pt; text-align: center;" width="148" data-mce-fragment="1">规技委副主任</td>\n' +
                '<td class="xl156" style="width: 81pt;" width="108" data-mce-fragment="1">史 磊</td>\n' +
                '<td class="xl156" style="width: 98pt; text-align: center;" width="130" data-mce-fragment="1">会议记录</td>\n' +
                '<td class="xl156" style="width: 85pt;" width="113" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl77" style="width: 103pt; height: 30pt; text-align: center;" width="137" height="40" data-mce-fragment="1">规技委成员</td>\n' +
                '<td class="xl157" style="width: 457pt; height: 30.0pt;" colspan="5" width="609" height="40" data-mce-fragment="1">杨石山、杨晖、丁楠、赵煊、何塨、张君、郑欣颖</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl157" style="width: 103pt; height: 30pt; text-align: center;" width="137" height="40" data-mce-fragment="1">其他参会人员</td>\n' +
                '<td class="xl300" style="width: 457pt; height: 30.0pt;" colspan="5" width="609" height="40" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl302" style="width: 560pt; height: 30.0pt;" colspan="6" width="746" height="40" data-mce-fragment="1">规技委审查意见：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 408.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl305" style="width: 560pt; height: 408.0pt;" colspan="6" width="746" height="544" data-mce-fragment="1">&nbsp;</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl306" style="width: 296pt; height: 30.0pt;" colspan="3" width="395" height="40" data-mce-fragment="1">经营部签字：<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></td>\n' +
                '<td class="xl307" style="width: 263pt; height: 30.0pt;" colspan="3" width="351" height="40" data-mce-fragment="1">规技委主任签字：</td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl306" style="width: 296pt; height: 30.0pt;" colspan="3" width="395" height="40" data-mce-fragment="1">所长签字：</td>\n' +
                '<td class="xl306" style="width: 263pt; height: 30.0pt;" colspan="3" width="351" height="40" data-mce-fragment="1">项目负责人签字：<span style="mso-spacerun: yes;" data-mce-fragment="1">&nbsp;</span></td>\n' +
                '</tr>\n' +
                '<tr style="height: 30.0pt;" data-mce-fragment="1">\n' +
                '<td class="xl156" style="width: 103pt; height: 30.0pt;" width="137" height="40" data-mce-fragment="1">要求</td>\n' +
                '<td class="xl310" style="width: 457pt; height: 30.0pt;" colspan="5" width="609" height="40" data-mce-fragment="1">三委会各次会议、项目组内部会议的记录。每一个项目组指定一名会议记录人员。</td>\n' +
                '</tr>\n' +
                '</tbody>\n' +
                '</table></div>'},

    ];
    return json;
}

module.exports.getjson = getjson;
